/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
      font-family: "AvenirNext";
      font-weight: 700;
      src: local('AvenirNext'), url(fonts/AvenirNext-Bold-01.ttf) format('opentype');
    }
  
    @font-face {
      font-family: "AvenirNext";
      font-weight: 600;
      src: local('AvenirNext'), url(fonts/AvenirNext-DemiBold-03.ttf) format('opentype');
    }
  
    @font-face {
      font-family: "AvenirNext";
      font-weight: 800;
      src: local('AvenirNext'), url(fonts/AvenirNext-Heavy-09.ttf) format('opentype');
    }
  
    @font-face {
      font-family: "AvenirNext";
      font-weight: 500;
      src: local('AvenirNext'), url(fonts/AvenirNext-Medium-06.ttf) format('opentype');
    }
  
    @font-face {
      font-family: "AvenirNext";
      font-weight: 400;
      src: local('AvenirNext'), url(fonts/AvenirNext-Regular-08.ttf) format('opentype');
    }
  
    @font-face {
      font-family: "AvenirNext";
      font-weight: 100;
      src: local('AvenirNext'), url(fonts/AvenirNext-UltraLight-11.ttf)  format('opentype');
    }
    @font-face {
      font-family: "SamsungOne";
      font-weight: 300;
      src: local('SamsungOne'), url(fonts/SamsungOne-300.ttf) format('opentype');
    }
  
    @font-face {
      font-family: "SamsungOne";
      font-weight: 400;
      src: local('SamsungOne'), url(fonts/SamsungOne-400.ttf) format('opentype');
    }
  
    @font-face {
      font-family: "SamsungOne";
      font-weight: 600;
      src: local('SamsungOne'), url(fonts/SamsungOne-600.ttf) format('opentype');
    }
  
    @font-face {
      font-family: "SamsungOne";
      font-weight: 700;
      src: local('SamsungOne'), url(fonts/SamsungOne-700.ttf) format('opentype');
    }
  
    @font-face {
      font-family: "SamsungOne";
      font-weight: 800;
      src: local('SamsungOne'), url(fonts/SamsungOne-800.ttf) format('opentype');
    }
  
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: url('./assets/ic-calendar_default.png') 100% no-repeat;
    margin-left: 10px;
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator {
    background: url('./assets/ic-calendar_default.png') 100% no-repeat;
    margin-left: 10px;
  }
  
  .react-datepicker__input-container input {
    @apply block px-3 w-36 py-1.5 mr-4 text-lg border border-gray-dark rounded
  }
  
  .react-datepicker__input-container input {
    padding-left: 10px;
    background: url('./assets/ic-calendar_default.png') 95% no-repeat;
  }
  
  .react-datepicker__tab-loop {
    @apply absolute
  }
  
  .react-datepicker-popper {
    @apply absolute top-1 z-10 left-0 w-72 text-sm transform-none bg-white shadow px-3 py-2 !important
  }
  
  .react-datepicker__month-container {
    @apply flex flex-col
  }
  
  .react-datepicker__month {
    @apply flex flex-col
  }
  
  .react-datepicker__current-month {
    @apply ml-2.5 text-lg font-semibold text-gray-800
  }
  
  .react-datepicker__week {
    @apply flex justify-around
  }
  
  .react-datepicker__day-names {
    @apply flex justify-around text-gray-800 font-medium text-center text-xs
  }
  
  .react-datepicker__day-name {
    @apply w-8 h-8 flex items-center justify-center py-1 rounded-full
  }
  
  .react-datepicker__navigation {
    @apply absolute top-2
  }
  
  .react-datepicker__navigation--previous {
    @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
  }
  
  .react-datepicker__navigation--next {
    @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200
  }
  
  .react-datepicker__day {
    @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded
  }
  
  .react-datepicker__day--disabled {
    @apply cursor-not-allowed opacity-50 hover:bg-transparent
  }
  
  .react-datepicker__day--outside-month {
    @apply text-gray-400
  }
  
  .react-datepicker__day--in-range {
    @apply bg-gray-200
  }
  
  .react-datepicker__day--in-selecting-range {
    @apply bg-blue-200
  }
  
  .react-datepicker__day--selecting-range-start {
    @apply bg-white border-2 border-blue-500
  }
  
  .react-datepicker__day--selecting-range-end {
    @apply bg-white border-2 border-blue-500
  }
  
  .react-datepicker__day--selected {
    @apply bg-blue-500 text-white
  }
  
  .react-datepicker__day--range-start {
    @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white
  }

  .react-datepicker__day--range-end {
    @apply bg-blue-500 text-white hover:text-gray-700 hover:bg-white
  }

 /* Password strength css*/
.password-strength-meter {
  text-align: left;
  font-size: 14px;
}

.password-strength-meter-progress {
  -webkit-appearance: none;
  appearance: none;
  width: 250px;
  height: 8px;
}

.password-strength-meter-progress::-webkit-progress-bar {
  background-color: #FFFFFF;
  border-radius: 8.5px;
  border: 1px solid #D5D9DB;
}

.password-strength-meter-progress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.inset-50 {
  top: 50%;
  right: 50%;
  bottom: 50%;
  left: 50%;
}

.disable-button{
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  border-color: rgba(156, 163, 175, var(--tw-bg-opacity));
}

.center{
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 100%;
}